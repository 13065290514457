import React, { Component } from "react"
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon
} from "react-share";
import config from "../utils/siteConfig"
// import "./SocialLinks.css";

const Share = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0px 0 40px;
  div {
    margin: 0px 5px;
  }
`

class ShareSns extends Component {
  render() {
    const { postNode, postPath, mobile } = this.props;
    const post = postNode.frontmatter;
    const url = postPath;
    const iconSize = mobile ? 36 : 48;

    return (
      <Share>
        <TwitterShareButton url={url} via="tolehico" title={postNode.title}>
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={postNode.excerpt}>
          <FacebookIcon round size={iconSize} />
        </FacebookShareButton>
      </Share>
    );
  }
}

export default ShareSns;
